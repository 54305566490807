<template>
  <div v-if="isLoggedIn" class="mt-3">
    <h1 class="mb-3">
      {{ $t('nav.acc.t') }}
    </h1>
    <b-card header-tag="nav" class="mt-3" body-class="p-2 p-sm-4">
      <template #header>
        <b-nav card-header tabs>
          <b-nav-item to="/account/" link-classes="px-2 px-sm-3" exact exact-active-class="active"><b-icon-person-lines-fill v-if="!isXs" v-bind="icon" /> {{ $t('nav.acc.uc') }}</b-nav-item>
          <b-nav-item to="/account/settings" link-classes="px-2 px-sm-3" exact exact-active-class="active"><b-icon-tools v-if="!isXs" v-bind="icon" /> {{ $t('nav.acc.sett') }}</b-nav-item>
          <b-nav-item to="/account/address" link-classes="px-2 px-sm-3" exact exact-active-class="active"><b-icon-house-door v-if="!isXs" v-bind="icon" /> {{ $t('nav.acc.add') }}</b-nav-item>
          <b-nav-item to="/account/favorites" link-classes="px-2 px-sm-3" v-if="isUser" exact exact-active-class="active"><b-icon-star v-if="!isXs" v-bind="icon" /> {{ $t('nav.acc.fav') }}</b-nav-item>
          <b-nav-item to="/account/restaurant" link-classes="px-2 px-sm-3" v-if="!isUser" exact exact-active-class="active"><b-icon-shop v-if="!isXs" v-bind="icon" /> {{ $t('nav.acc.rest') }}</b-nav-item>
          <b-nav-item to="/account/seats" link-classes="px-2 px-sm-3" v-if="!isUser" exact exact-active-class="active"><b-icon-grid v-if="!isXs" v-bind="icon" /> {{ $t('nav.acc.seat') }}</b-nav-item>
          <b-nav-item to="/account/tables" link-classes="px-2 px-sm-3" v-if="!isUser" exact exact-active-class="active"><b-icon-collection v-if="!isXs" v-bind="icon" /> {{ $t('nav.acc.tabl') }}</b-nav-item>
          <!-- <b-nav-item to="/account/optional" link-classes="px-2 px-sm-3" v-if="!isUser" exact exact-active-class="active">{{ $t('nav.acc.opt') }}</b-nav-item> -->
        </b-nav>
      </template>
      <router-view />
    </b-card>
  </div>
</template>

<script>
import { BIconPersonLinesFill, BIconTools, BIconStar, BIconHouseDoor, BIconShop, BIconGrid, BIconCollection, } from 'bootstrap-vue';

export default {
  computed: {
    isLoggedIn() {
      return this.$store.state.oLogin.isLoggedIn;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    icon() {
      return this.$store.getters.iH;
    },
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.replace('/').catch(() => {});
    }
  },
  components: {
    BIconPersonLinesFill,
    BIconTools,
    BIconStar,
    BIconShop,
    BIconHouseDoor,
    BIconGrid,
    BIconCollection,
  },
}
</script>
